/* width: 100%;
position: fixed;
top: 0;
z-index: 80;
background-color: #efefed; */
.opacanimation {
    animation: opacanimation1 0.5s ease-in-out;
}

.nav-menu {
    background-color: #efefed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    font-size: 23px;
    pointer-events: none;
    font-family: 'Caramorant Garamond', serif;
    transition: all 01s ease;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.nav-menu.active {
    background-color: #efefed;
    left: 0;
    top: 0;
    height: 100vh;
    font-size: 32px;
    pointer-events: all;
    opacity: 1;
    line-height: 1.8;
    font-family: 'Caramorant Garamond', serif;
    /* transition: all 1s ease-out; */
    align-items: center;
    text-align: center;
    justify-content: center;
}

.nav-links {
    text-align: center;
    padding: 0.5rem;
    margin: auto;
    width: 100%;
    display: table;
    color: black;
    text-decoration: none;
    line-height: 1.5;
}

.nav-links:hover {
    text-align: center;
    padding: 0.5rem;
    width: 100%;
    display: table;
    color: black;
    font-weight: 500;
    line-height: 1.5;
}

.nav-menu ul li a:hover {
    border-bottom: '2px solid red';
    text-decoration-thickness: 10%;
}

.nav-logo {
    position: absolute;
    left: 1%;
    margin-top: -0.9rem;
    width: 60px;
}

.nav-menu ul li {
    list-style: none;
}

.navbar-height {
    height: 4.5rem;
    background-color: #efefed;
}

.menu-icon {
    display: block;
    position: absolute;
    top: -10px;
    right: -5px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 500;
    color:black;
}
.menu-icon:hover{
    color:black;
}

.fa-times {
    color: black;
    font-size: 2rem;
    margin-top: -20px;
}

.fa-bars {
    color: black;
    font-size: 2rem;
    margin-top: -20px;

}

.opacanimation3 {
    animation-delay: 1s;
    animation: opacanimation2 0.5s ease-in-out;

}

@keyframes opacanimation1 {
    0% {
        opacity: 0;
        z-index: 0;
    }

    100% {
        opacity: 1;
        z-index: 1;
    }
}

@keyframes opacanimation2 {
    0% {
        opacity: 1;
        z-index: 1;
    }

    100% {
        opacity: 0;
        z-index: -1;
    }
}