body {
    background: #efefed;
    font-family: 'Cormorant Garamond', serif;
    scrollbar-width: none;
}

.container- {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: auto;
    scroll-snap-type: y mandatory;
}

.container- .section {
    position: relative;
    width: 100%;
    height: 100%;
    scroll-snap-align: end;
    background-blend-mode: multiply;

}

.container- .section .content {
    position: absolute;

}

.container-::-webkit-scrollbar {
    display: none;
}

.container- .section:nth-child(1) {
    background: #efefed;
    scroll-snap-stop: always;
    background-attachment: fixed;
    background-size: cover;

}

.container- .section:nth-child(2) {
    background: #efefed;
    scroll-snap-stop: always;
    background-attachment: fixed;
    background-size: cover;
}

.container- .section:nth-child(3) {
    background: #efefed;
    scroll-snap-stop: always;
    background-attachment: fixed;
    background-size: cover;
}

.container- .section:nth-child(4) {
    background: #efefed;
    scroll-snap-stop: always;
    background-attachment: fixed;
    background-size: cover;
}

.container- .section:nth-child(5) {
    background: #efefed;
    scroll-snap-stop: always;
    background-attachment: fixed;
    background-size: cover;
}

.container- .section:nth-child(6) {
    background: #efefed;
    scroll-snap-stop: always;
    background-attachment: fixed;
    background-size: cover;
}

.container- .section:nth-child(7) {
    background: #efefed;
    scroll-snap-stop: always;
    background-attachment: fixed;
    background-size: cover;
}

.l-text {
    /* margin: auto; */
    font-size: 88px;
    font-weight: 600;
    margin-right: 10%;
    text-align: right;
    font-family: 'Cormorant Garamond', serif;
}

.l-text-sm {
    margin-right: 10%;
    font-size: 34px;
    font-weight: 500;
    text-align: right;
    font-family: 'Cormorant Garamond', serif;
}

.l-text-focus {
    font-size: 95px;
    font-weight: 600;
    color: white;
    /* margin-top: -2rem; */
    display: flex;
    text-align: left;
    font-family: 'Cormorant Garamond', serif;
}
.symphony-num{
    font-family: "Poppins";
}
/* .l-text-sm-ind {
    display: flex;
    font-size: 34px;
    font-weight: 500;
    color: white;
    font-family: 'Cormorant Garamond', serif;
} */

.set-sail {
    font-family: 'Cormorant Garamond', serif;
    font-size: 40px;
    font-weight: 500;
}

.ripple-text {
    font-family: 'Cormorant Garamond', serif;
    font-size: 28px;
    font-weight: 600;
}

.tech-text {
    font-size: 26px;
    font-family: 'Cormorant Garamond', serif;
}

.deep-tech {
    border: 1px solid black;
    height: 26vh;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: 'Cormorant Garamond', serif;
}

.highlight-tech {
    width: 100%;
    text-align: left;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: 'Cormorant Garamond', serif;
}

.expenditure-ml {
    width: 100%;
    font-size: 100%;
    margin-left: -10%;
}

.padding-ind {
    background-color: black;
    padding: 13% 11.5% 6% 16%;
}

.garamond-text {
    font-size: 16px;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 500;
    line-height: 22px;
}

.padding-tech {
    background-color: #efefed;
}

.card-tech {
    background-color: #efefed;
    padding: 5%;
    border: 1px solid black;
    border-radius: 0px;
    height: auto;
    width: 98%;
}

/* .round-circle {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    border: 1px solid black;
    display: inline-block;
    margin-right: 5px;
} */

ul.span {
    list-style-position: outside;
    list-style-type: circle;
    margin-left: -10px;
}

.enable-center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
    width: 100%;
    border: none;
    min-height: 100vh;
    margin-top: 2%;
}
.enable-symphony {
    /* display: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
    width: 100%;
    border: none;
    position: absolute;
    top:18vh;
    min-height: 50vh; */
    
    width: 100%;
    border: none;
    margin: 2vh auto;
    padding-bottom: 3rem;
    min-height: 35vh;
    padding-top: 12vh;
}
.symphony-top{
    margin-top:8%;
}

.symphony-text {
    font-size: 88px;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 500;
    line-height: 22px;
    font-weight: 500;
    text-align: left;
    margin-left:12%;
}
.symphony-text-sm {
    font-size: 26px;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 500;
    line-height: 30px;
    margin: 0 6vw;
    position:absolute;
    bottom:6vh;
    text-align: center;
    justify-content: center;
}
.animated-container{
    font-size:240px;
    font-weight:500;
    font-family: 'Baskerville Old Face' !important;
    display:flex;
    text-align: right;
    justify-content: right;
    margin-right:1.2rem;
}
.symphony-amount {
    font-size: 240px;
    font-family: 'Baskerville Old Face', serif;
}
.updated-weekly{
    font-size: 18px;
    font-family:'Cormorant Garamond', serif;
    font-weight: 500;
    margin-top:-3.5rem;
    text-align: right;
    justify-content: right;
    margin-right: 3.5rem;
}
.m-auto {
    margin-left: 10%;
    margin-right: 10%;
}

.c-focus {
    width: 84%;
    border: none;
    margin: 0 8% 0 8%;
    padding-bottom: 3rem;
    min-height: 35vh;
    padding-top: 15vh;
}
.c-symphony {
    width: 100%;
    border: none;
    margin: auto;
    padding-bottom: 3rem;
    min-height: 35vh;
    padding-top: 15vh;
}

.investor-focus {
    width: 84%;
    border: none;
    margin: 0 8% 0 8%;
    padding-bottom: 3rem;
    min-height: 35vh;
    padding-top: 6.5%;
}

#pre-content::before {
    content: "\a";
    white-space: pre;
    margin-left: 0;
}

.text-Align {
    text-align: center;
    align-items: center;
    justify-content: center;
}

#pre-content1::before {
    content: "\a";
    white-space: pre;
    margin-left: 0;
    text-align: center;
}

.c-center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    border: none;
    min-height: 100vh;
}



.enable-img {
    width: 80%;
    height: 80%;
}

.inspire-img {
    width: 95%;
    height: 95%;
}

.achieve-img {
    width: 80%;
    height: 80%;
    margin-left: 7%;
    margin-right: 0%;
}

.ripple-img {
    margin-top: 10px;
    margin-bottom: 15px;
    width: 55%
}

.wave-img {
    margin-top: 10px;
    margin-bottom: 15px;
    width: 48%
}

.port-img {
    margin-top: 20px;
    margin-bottom: 10px;
    width: 30%;
}

.deck-img {
    margin-top: 0.5rem;
    margin-bottom: 1.7rem;
    width: 65%;
}

.bridge-img {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 32%;
}

/* mark {
    --color1: #ffb100;
    --color2: #ffb100;
    --height: 100%;
    all: unset;
    background-image: linear-gradient(var(--color1), var(--color2));
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0 var(--height);
    animation: highLight 0.9s 1 ease-out;
    animation-fill-mode: forwards;
    animation-play-state: paused;
}

main {
    width: min(50ch, 80%);
    margin: 0 auto;
    padding: 100px 0;
}

@keyframes highLight {
    to {
        background-size: 100% var(--height);
    }
} */

.highlight-container,
.highlight {
    position: relative;
}

.highlight-container {
    display: inline-block;
    cursor: pointer;
}

.highlight-container:before {
    content: " ";
    display: block;
    height: 90%;
    width: 100%;
    margin-right: 0px;
    margin-left: -3px;
    position: absolute;
    background-color: none;
    background: linear-gradient(90deg, #ffa200 50%, transparent 50%);
    animation: 2s highlight 2s 2 normal forwards;
    background-size: 200% 100%;
    background-position: 100% 0;
    transform: rotate(-2deg);
    top: -1px;
    left: -1px;
    border-radius: 20% 25% 20% 24%;
    padding: 10px 3px 3px 10px;
}

.highlight-container-g,
.highlight {
    position: relative;
}

.highlight-container-g {
    display: inline-block;
    cursor: pointer;
}

.highlight-container-g:before {
    content: " ";
    display: block;
    height: 90%;
    width: 100%;
    margin-left: -3px;
    margin-right: 0px;
    position: absolute;
    background-color: none;
    background: linear-gradient(90deg, #2d9c36 50%, transparent 50%);
    animation: 2s highlight 2s 1 normal forwards;
    background-size: 200% 100%;
    background-position: 100% 0;
    transform: rotate(-2deg);
    top: -1px;
    left: -1px;
    border-radius: 20% 25% 20% 24%;
    padding: 10px 3px 3px 10px;
}

@-webkit-keyframes highlight {
    to {
        background-position: 0 0;
    }
}

@keyframes highlight {
    to {
        background-position: 0 0;
    }
}

.circle-yellow {
    width: 10px;
    height: 10px;
    background-color: #ffa200;
    border-radius: 50%;
    display: inline-block;
}

.circle-green {
    width: 10px;
    height: 10px;
    background-color: #2d9c36;
    border-radius: 50%;
    display: inline-block;
}

.hide {
    display: none;
}

.cursor {
    cursor: pointer;
}

.myDIV:hover+.hide {
    position: fixed;
    top: 13%;
    left: 28%;
    width: 200px;
    height: auto;
    display: block;
    color: black;
    border: 1px solid black;
    background-color: white;
    text-align: left;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 3px 0 3px -2px #888;
}


.pro {
    text-align: left;
    font-size: 12px;
}

.best-text {
    text-align: left;
    font-size: 14px;
}

.left-align {
    text-align: left;
    font-size: 15px;
}

.tooltip_img {
    width: 75px;
    float: right;
    margin-top: -7%;
}

.tooltip_img1 {
    width: 55px;
    float: right;
    margin-top: -9.5%;
}

.tooltip_img2 {
    width: 55px;
    float: right;
    margin-top: -9.5%;
}

.tooltip-inner {
    background-color: white;
    color: black;
    padding: 0.5rem;
    border-color: 1px solid black;
    max-width: 300px !important;
    width: 275px !important;
}

.tooltip.bs-tooltip-top .arrow:before {
    border-top-color: white !important;
}

.tooltip.bs-tooltip-left .arrow:before {
    border-left-color: white !important;
}

.tooltip.bs-tooltip-right .arrow:before {
    border-right-color: white !important;
}

.tooltip.bs-tooltip-bottom .arrow:before {
    border-bottom-color: white !important;
}


a span {
    display: none;
    position: absolute;
    color: #fff;
    background: #000;
    padding: 5px;
}

a {
    position: relative;
}

a:hover span {
    display: block;
    text-align: center;
}

#i-content::before {
    content: "\a";
    white-space: pre;
}

@media (min-width: 750px) and (max-width: 992px) {
    #i-content::before {
        content: "";
        white-space: normal;
    }

    .top-margin {
        margin-top: 4rem;
        background: #efefed;
        margin-right: -20px;
    }

    .l-text {
        font-size: 60px;
        font-weight: 600;
        text-align: center;
        font-family: 'Cormorant Garamond', serif;
    }

    .l-text-achieve {
        font-size: 60px;
        font-weight: 600;
        text-align: center;
        font-family: 'Cormorant Garamond', serif;
    }

    .l-text-sm {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        margin-left: 0;
        margin-right: 0;
        font-family: 'Cormorant Garamond', serif;
    }

    .l-text-focus {
        font-size: 65px;
        font-weight: 600;
        color: white;
        margin-top: -2rem;
        display: flex;
        text-align: left;
        font-family: 'Cormorant Garamond', serif;
    }

    .l-text-sm-ind {
        display: flex;
        font-size: 20px;
        font-weight: 500;
        color: white;
        font-family: 'Cormorant Garamond', serif;
    }

    .enable-img {
        width: 100%;
        height: 100%;
    }

    .inspire-img {
        width: 100%;
        height: 100%;
    }

    .achieve-img {
        width: 100%;
        height: 100%;
    }

    .ripple-img {
        margin-top: 10px;
        margin-bottom: 15px;
        width: 55%;
    }

    .port-img {
        margin-top: 10px;
        margin-bottom: 15px;
        width: 24%;
    }

    .section1 {
        transition: 0.5s ease-in;
    }

    .deck-img {
        margin-top: 10px;
        margin-bottom: 17px;
        width: 60%;
    }

    .bridge-img {
        margin-top: 10px;
        margin-bottom: 15px;
        width: 25%;
    }

    .expenditure-ml {
        width: 100%;
        margin: auto;
        font-size: 16px;
    }

    #pre-content::before {
        content: "";
        white-space: none;
        margin-left: 6px;
    }

    #pre-content1::before {
        content: "";
        white-space: none;
        margin-left: 6px;
    }

    .container- {
        position: relative;
        width: 100%;
        height: 100vh;
        overflow: auto;
        scroll-snap-type: y mandatory;
    }

    .container- .section {
        position: relative;
        width: 100%;
        height: 100%;
        scroll-snap-align: end;
        background-blend-mode: multiply;

    }

    .container- .section .content {
        position: absolute;

    }

    .container-::-webkit-scrollbar {
        display: none;
    }

    .container- .section:nth-child(1) {
        background: #efefed;
        scroll-snap-stop: always;
        background-attachment: fixed;
        background-size: cover;

    }

    .container- .section:nth-child(2) {
        background: #efefed;
        scroll-snap-stop: always;
        background-attachment: fixed;
        background-size: cover;
    }

    .container- .section:nth-child(3) {
        background: #efefed;
        scroll-snap-stop: always;
        background-attachment: fixed;
        background-size: cover;
    }

    .container- .section:nth-child(4) {
        background: #efefed;
        scroll-snap-stop: always;
        background-attachment: fixed;
        background-size: cover;
    }

    .container- .section:nth-child(5) {
        background: #efefed;
        scroll-snap-stop: always;
        background-attachment: fixed;
        background-size: cover;
    }

    .container- .section:nth-child(6) {
        background: #efefed;
        scroll-snap-stop: always;
        background-attachment: fixed;
        background-size: cover;
    }

    .container- .section:nth-child(7) {
        background: #efefed;
        scroll-snap-stop: always;
        background-attachment: fixed;
        background-size: cover;
    }

    .c-focus {
        width: 84%;
        height: 100%;
        border: none;
        margin: 0% 8% 8% 8%;
        padding-bottom: 0rem;
        padding-top: 25%;
    }
    .c-symphony {
        width: 100%;
        height: 100%;
        border: none;
        margin: auto;
        padding-bottom: 0rem;
        padding-top: 25%;
    }

    .deep-tech {
        border: 1px solid black;
        height: 27vh;
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        display: flex;
        padding: 25% 8%;
        font-family: 'Cormorant Garamond', serif;
    }

    .investor-focus {
        width: 84%;
        height: 100%;
        border: none;
        margin: 0% 8% 0 8%;
        padding-bottom: 0rem;
        padding-top: 25%;
    }
}

@media screen and (max-width:760px) {
    .symphony-top{
        margin-top:12vh;
    }
    .enable-symphony {
        /* display: auto;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: auto;
        width: 100%;
        border: none;
        position: absolute;
        top:18vh;
        min-height: 50vh; */
        
        /* width: 100%;
        height:100%;
        border: none;
        margin: 5vh auto;
        padding-bottom: 3rem;
        min-height: 35vh;
        padding-top: 12vh; */
        width: 100%;
        height: 100%;
        border: none;
        margin: auto;
        padding-bottom: 10vh;
        padding-top: 20vh;
    }
    .animated-text-flex{
        display:flex;
        text-align: right;
        justify-content: right;
        margin-right: 1.5rem;
    }
    .symphony-text {
        font-size: 48px;
        font-family: 'Cormorant Garamond', serif;
        font-weight: 500;
        line-height: 22px;
        font-weight: 500;
        text-align: left;
        margin-left:3%;
    }
    .symphony-text-sm {
        font-size: 24px;
        font-family: 'Cormorant Garamond', serif;
        font-weight: 500;
        line-height: 32px;
        /* margin:7vh 5vw;
        margin: 0 5vw; */
        position:absolute;
        bottom:20vh;
        text-align: justify;
        justify-content: center;
    }
    .symphony-amount {
        font-size: 80px;
        font-family: 'Baskerville Old Face', serif;
    }
    .animated-container{
        font-size:80px;
        font-weight:500;
        font-family: 'Baskerville Old Face';
        display:flex;
        text-align: right;
        justify-content: right;
        margin-right:0.6rem;
    }
    .updated-weekly{
        font-size: 18px;
        font-family:'Cormorant Garamond', serif;
        font-weight: 500;
        margin-top:-1rem;
        text-align: right;
        justify-content: right;
        margin-right: 1.5rem;
    }
    #i-content::before {
        content: "";
        white-space: normal;
    }

    .top-margin {
        margin-top: 4rem;
        background: #efefed;
        margin-right: -20px;
    }

    .l-text {
        font-size: 60px;
        font-weight: 600;
        text-align: center;
        font-family: 'Cormorant Garamond', serif;
    }

    .l-text-achieve {
        font-size: 60px;
        font-weight: 600;
        text-align: center;
        font-family: 'Cormorant Garamond', serif;
    }

    .l-text-sm {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        margin-left: 0;
        margin-right: 0;
        font-family: 'Cormorant Garamond', serif;
    }

    .l-text-focus {
        font-size: 65px;
        font-weight: 600;
        color: white;
        margin-top: -2rem;
        display: flex;
        text-align: left;
        font-family: 'Cormorant Garamond', serif;
    }

    .l-text-sm-ind {
        display: flex;
        font-size: 20px;
        font-weight: 500;
        color: white;
        font-family: 'Cormorant Garamond', serif;
    }

    .enable-img {
        width: 100%;
        height: 100%;
    }

    .inspire-img {
        width: 100%;
        height: 100%;
    }

    .achieve-img {
        width: 90%;
        height: 90%;
        margin-left: 5%;
        margin-right: 5%;
    }

    .ripple-img {
        margin-top: 10px;
        margin-bottom: 15px;
        width: 55%;
    }

    .port-img {
        margin-top: 10px;
        margin-bottom: 15px;
        width: 24%;
    }

    .section1 {
        transition: 0.5s ease-in;
    }

    .deck-img {
        margin-top: 10px;
        margin-bottom: 17px;
        width: 60%;
    }

    .bridge-img {
        margin-top: 10px;
        margin-bottom: 15px;
        width: 25%;
    }

    .expenditure-ml {
        width: 100%;
        margin: auto;
        font-size: 16px;
    }

    #pre-content::before {
        content: "";
        white-space: none;
        margin-left: 6px;
    }

    #pre-content1::before {
        content: "";
        white-space: none;
        margin-left: 6px;
    }

    .container- {
        position: relative;
        width: 100%;
        height: 100vh;
        overflow: auto;
        scroll-snap-type: y mandatory;
    }

    .container- .section {
        position: relative;
        width: 100%;
        height: 100%;
        scroll-snap-align: end;
        background-blend-mode: multiply;

    }

    .container- .section .content {
        position: absolute;

    }

    .container-::-webkit-scrollbar {
        display: none;
    }

    .container- .section:nth-child(1) {
        background: #efefed;
        scroll-snap-stop: always;
        background-attachment: fixed;
        background-size: cover;

    }

    .container- .section:nth-child(2) {
        background: #efefed;
        scroll-snap-stop: always;
        background-attachment: fixed;
        background-size: cover;
    }

    .container- .section:nth-child(3) {
        background: #efefed;
        scroll-snap-stop: always;
        background-attachment: fixed;
        background-size: cover;
    }

    .container- .section:nth-child(4) {
        background: #efefed;
        scroll-snap-stop: always;
        background-attachment: fixed;
        background-size: cover;
    }

    .container- .section:nth-child(5) {
        background: #efefed;
        scroll-snap-stop: always;
        background-attachment: fixed;
        background-size: cover;
    }

    .container- .section:nth-child(6) {
        background: #efefed;
        scroll-snap-stop: always;
        background-attachment: fixed;
        background-size: cover;
    }

    .container- .section:nth-child(7) {
        background: #efefed;
        scroll-snap-stop: always;
        background-attachment: fixed;
        background-size: cover;
    }

    .c-focus {
        width: 84%;
        height: 100%;
        border: none;
        margin: 0% 8% 0 8%;
        padding-bottom: 0rem;
        padding-top: 25%;
    }
    .c-symphony {
        width: 100%;
        height: 100%;
        border: none;
        margin:auto;
        padding-bottom: 3rem;
        padding-top: 18vh;
    }

    .deep-tech {
        border: 1px solid black;
        height: 20vh;
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        display: flex;
        padding: 25% 8%;
        font-family: 'Cormorant Garamond', serif;
    }

    .investor-focus {
        width: 84%;
        height: 100%;
        border: none;
        margin: 0% 8% 0 8%;
        padding-bottom: 0rem;
        padding-top: 25%;
    }
}

.section1 {
    transition: 1s ease;
}