body {
    background: #efefed;
    font-family: 'Cormorant Garamond', serif;
}

.service::before {
    content: '';
    display: block;
    height: 12vh;
    margin-top: -12vh;
    visibility: hidden;
}

.min-h-screen {
    height: 100vh;
    min-height: 100vh;
    margin: auto;
    width: 100%;
    border: none;
    background-color: #efefed;
}

.min-h-screen1 {
    height: 100vh;
    min-height: 100vh;
    margin: auto;
    width: 100%;
    border: none;
    background-color: blue;
}

.min-h-screen2 {
    height: 100vh;
    min-height: 100vh;
    margin: auto;
    width: 100%;
    border: none;
    background-color: green;
}

.flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-col {
    flex-direction: column;
}

.items-center {
    align-items: center;
}

.items-end {
    align-items: flex-end;
    margin-left: -2.3%;
}

.items-start {
    align-items: flex-start;
}

.mr-right {
    margin-right: 5%;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}


.justify-around {
    justify-content: space-around;
}

.justify-end {
    justify-content: flex-end;
}

.justify-start {
    justify-content: flex-start;
}

.justify-evenly {
    justify-content: space-evenly;
}

.text-xl {
    font-size: 100px;
    font-weight: bolder;
}

.bg-black {
    background-color: black;
    width: 100%;
}

.text-black {
    color: black;
}

.bg-white {
    background-color: white;
}

.text-white {
    color: white;
}

.cardRow {
    width: 100%;
    min-height: 101vh;
}

.text-xl {
    font-size: 70px;
}

.text-base {
    font-size: 30px;
}

.loading-animation {
    animation: loadingAnimation 1s ease-in-out;
}

@keyframes loadingAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@media screen and (max-width:960px) {
    .text-xl {
        font-size: 70px;
    }

    .text-base {
        font-size: 30px;
    }
}

@media screen and (max-width: 769px) {
    /* img {
        max-height: 300px;
        object-fit: contain;
    } */

    .service::before {
        content: '';
        display: block;
        height: 20vh;
        margin-top: -20vh;
        visibility: hidden;
    }

    .text-xl {
        font-size: 50px;
    }

    .text-base {
        font-size: 24px;
    }

    .sm-h-500 {
        min-height: 300px;
    }
}