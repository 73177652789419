* {
    outline: none;
}

.footer_bg {
    width: 100%;
    height: 100vh;
    background: rgb(230 231 233);
}

.color {
    color: white;
    text-decoration: underline;
    text-decoration-color: white !important;
}

.footer-top {
    margin: 5% 8% 5% 8.3%;
}

.say-hello {
    margin-top: 15vh;
    font-size: 60px;
    font-family: 'Caramorant Garamond', serif;
    font-weight: 500;
}

.box-width {
    width: 95%;
}

.image-top-gap {
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 32vh;
}

.top-gap {
    margin-top: 3%;
}

.need-help {
    font-size: 40px;
    font-family: 'Caramorant Garamond', serif;
    font-weight: 500;
}

.submit-btn {
    width: 5.5rem;
    height: 2.4rem;
    color: white;
    border-radius: 25px;
    font-size: 17px;
    padding-top: -5px;
    background: black;
}

.submit-btn:hover {
    width: 5.5rem;
    height: 2.4rem;
    color: white;
    font-size: 17px;
    padding-top: -5px;
    border-radius: 25px;
    background: black;
}

.open-btn {
    width: 10rem;
    height: 2.4rem;
    color: white;
    border-radius: 20px;
    background: black;
}

.open-btn:hover {
    width: 10rem;
    height: 2.4rem;
    color: white;
    border-radius: 20px;
    background: black;
}

.input-border {
    width: 100%;
    height: 4.9vh;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-bottom: 4.8vh;
    border: none;
    border-bottom: 0.5px solid black;
}

.text {
    width: 100%;
    height: 100%;
    padding: 0 10px;
    border: none;
    border-bottom: 0.5px solid black;
    background: none;
    font-size: 16px;
    color: black;
}

.border {
    position: absolute;
    bottom: -1px;
    left: 0px;
    width: 0;
    height: 2px;
    background: black;
    transition: .5s;
}

.text:focus~.border,
.text:valid~.border {
    width: 100%;
    color: black;
}

.text:hover {
    border-bottom: 0.5px black;
}

.label {
    position: absolute;
    top: 8px;
    left: 14px;
    color: black;
    pointer-events: none;
    font-size: 17px;
    transition: .5s;
}


.text:focus~.label,
.text:valid~.label {
    top: -18px;
    left: 10px;
    color: black;
    font-size: 17px;
}

.world-img {
    margin-left: 8%;
    width: 40vw;
}

.copyright-text {
    font-size: 20px;
    font-family: 'Caramorant Garamond', serif;
    font-weight: 500;
    position: absolute;
    bottom: 2.5vh;
    left: 25%;
    right: 25%
}

.bg-hr {
    background-color: black;
    width: 84%;
    position: absolute;
    bottom: 6vh;
    left: 5%;
    right: 5%;
}

.bottom-icon {
    width: 7%;
    position: absolute;
    right: 0.7%;
    bottom: 30px;
}

@media screen and (max-width:639px) {
    .bottom-icon {
        width: 50px;
        position: absolute;
        right: 5px;
        bottom: 5%;
    }

    .input-border {
        width: 100%;
        height: 4.5vh;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        margin-bottom: 2vh;
        border: none;
        border-bottom: 0.5px solid black;
    }

    .text {
        width: 100%;
        height: 100%;
        padding: 0 10px;
        border: none;
        border-bottom: 0.5px solid black;
        background: none;
        font-size: 16px;
        color: black;
    }

    .border {
        position: absolute;
        bottom: -1px;
        left: 0px;
        width: 0;
        height: 2px;
        background: black;
        transition: .5s;
    }

    .text:focus~.border,
    .text:valid~.border {
        width: 100%;
        color: black;
    }

    .text:hover {
        border-bottom: 0.5px black;
    }

    .label {
        position: absolute;
        top: 8px;
        left: 14px;
        color: black;
        pointer-events: none;
        font-size: 17px;
        transition: .5s;
    }


    .text:focus~.label,
    .text:valid~.label {
        top: -18px;
        left: 10px;
        color: black;
        font-size: 17px;
    }

    .top-gap {
        margin-top: 1%;
    }


    .footer_bg {
        width: 100%;
        height: 100vh;
        background-color: rgb(231 231 233);
    }

    .footer-top {
        margin: 4% 2% 5% 8.3%;
    }

    .say-hello {
        margin-top: 7vh;
        font-size: 56px;
        font-family: 'Caramorant Garamond', serif;
        font-weight: 500;
    }

    .box-width {
        width: 100%;
    }

    .need-help {
        font-size: 40px;
        font-family: 'Caramorant Garamond', serif;
        font-weight: 500;
    }

    .image-top-gap {
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: 2vh;
    }

    .world-img {
        margin-left: 2.5%;
        width: 98%;
    }

    .copyright-text {
        font-size: 16px;
        font-family: 'Caramorant Garamond', serif;
        font-weight: 500;
        position: absolute;
        bottom: 1%;
        display: flex;
        left: 8.5%;
        right: 5%
    }

    .bg-hr {
        background-color: black;
        width: 84%;
        position: absolute;
        bottom: 2%;
        left: 4%;
        right: 4%;
    }
}

@media (min-width: 640px) and (max-width: 992px) {
    .input-border {
        width: 100%;
        height: 30px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        margin-bottom: 30px;
        border: none;
        border-bottom: 0.5px solid black;
    }

    .text {
        width: 100%;
        height: 100%;
        padding: 0 10px;
        border: none;
        border-bottom: 0.5px solid black;
        background: none;
        font-size: 16px;
        color: black;
    }

    .border {
        position: absolute;
        bottom: -1px;
        left: 0px;
        width: 0;
        height: 2px;
        background: black;
        transition: .5s;
    }

    .text:focus~.border,
    .text:valid~.border {
        width: 100%;
        color: black;
    }

    .text:hover {
        border-bottom: 0.5px black;
    }

    .label {
        position: absolute;
        top: 8px;
        left: 14px;
        color: black;
        pointer-events: none;
        font-size: 17px;
        transition: .5s;
    }


    .text:focus~.label,
    .text:valid~.label {
        top: -18px;
        left: 10px;
        color: black;
        font-size: 17px;
    }

    .top-gap {
        margin-top: 1rem;
    }


    .footer_bg {
        width: 100%;
        height: 100vh;
        background-color: rgb(231 231 233);
    }

    .footer-top {
        margin: 5% 8% 5% 8.3%;
    }

    .say-hello {
        margin-top: 15vh;
        font-size: 60px;
        font-family: 'Caramorant Garamond', serif;
        font-weight: 500;
    }

    .box-width {
        width: 100%;
    }

    .need-help {
        font-size: 40px;
        font-family: 'Caramorant Garamond', serif;
        font-weight: 500;
    }

    .image-top-gap {
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: 10vh;
    }

    .world-img {
        margin-left: 4%;
        width: 98%;
    }

    .copyright-text {
        font-size: 16px;
        font-family: 'Caramorant Garamond', serif;
        font-weight: 500;
        position: absolute;
        bottom: 1.5%;
        display: flex;
        left: 5%;
        right: 5%
    }

    .bg-hr {
        background-color: black;
        width: 80%;
        position: absolute;
        bottom: 3%;
        left: 5%;
        right: 5%;
    }

    /* .hr {
        width: 100%;
        color: black;
        font-size: 16px;
        font-family: 'Caramorant Garamond', serif;
        font-weight: 500;
        position: absolute;
        bottom: 8%;
        display: flex;
    } */
}